<template>
  <!-- 个人中心 - 订单中心 -->
  <div class="collect">
    <div class="title">
      <p>订单中心</p>
    </div>
    <el-table :data="tableData" border :cell-style="{ background: '#fff' }" style="width: 100%">
      <el-table-column prop="order_id" label="订单号" min-width="130">
      </el-table-column>
      <el-table-column prop="order_type" label="购买类型" min-width="130">
      </el-table-column>
      <el-table-column prop="payment_amount" label="支付金额" min-width="130">
      </el-table-column>
      <el-table-column label="订单状态" min-width="130">
        <template slot-scope="scope">
          {{ scope.row.payment_status == 1 ? "未支付" : "已支付" }}
        </template>
      </el-table-column>
      <el-table-column label="支付方式" min-width="130">
        <template slot-scope="scope">
          {{
          scope.row.payment_method == 1
          ? "微信"
          : scope.row.payment_method == 2
          ? "支付宝"
          : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="payment_time" label="购买时间" min-width="130">
        <template slot-scope="scope">
          {{
          scope.row.payment_time == "0000-00-00 00:00:00"
          ? "-"
          : scope.row.payment_time
          }}
        </template>
      </el-table-column>
      <el-table-column prop="vip_end_time" label="到期时间" min-width="130">
        <template slot-scope="scope">
          {{
          scope.row.vip_end_time == "0000-00-00 00:00:00"
          ? "-"
          : scope.row.vip_end_time
          }}
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="250">
        <template slot-scope="scope">
          <div>{{ scope.purchase_type }}</div>
          <template v-if="intervalObj[scope.row.id]">
            <el-button v-if="scope.row.payment_status == 1" size="mini" :class="{not: canBuy(scope.row)}" type="success"
              @click="canBuy(scope.row) ? '' : handlePay(scope.row)">
              立即支付</el-button>
            <el-button v-if="scope.row.payment_status == 1" size="mini" type="danger" @click="handleDelete(scope.row)">
              取消</el-button>
            <div class="time" v-if="scope.row.payment_status == 1">
              <template v-if="intervalObj[scope.row.id]">
                <span>{{ intervalObj[scope.row.id].minute }}</span>：<span>{{ intervalObj[scope.row.id].second
                }}</span>：<span>{{ intervalObj[scope.row.id].millisecond }}</span>
              </template>
              <template v-else>
                <span>00</span>：<span>00</span>：<span>00</span>
              </template>
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <default-page style="margin-top: 60px" v-if="tableData.length == 0" status="nodata"></default-page>

    <el-pagination background :page-size="page_size" hideOnSinglePage layout="prev, pager, next"
      @current-change="getList" :total="total">
    </el-pagination>
    <pay v-if="payInfo.visible" :payInfo="payInfo" @close="closePay" />
  </div>
</template>
<script>
import DefaultPage from "@/components/default";
import Pay from "../../components/pay";
import { GlobalApi } from "@/config/axios/api";
import { mapState } from "vuex";

export default {
  name: "Order",
  data () {
    return {
      tableData: [],
      page: 1,
      page_size: 30,
      total: 0,
      intervalObj: {},
      payInfo: {
        visible: false,
      },
      teamUser: {}, //在团队中的信息
      showSelect: false,
    };
  },
  components: { DefaultPage, Pay },
  created () {
    this.getList(1);
  },
  computed: {
    ...mapState(['loginInfo']),
  },
  methods: {
    // 是否可购买
    canBuy (item) {
      return (this.loginInfo.is_sc_vip == 1 && this.loginInfo.sc_vip_level == 3 && item.vip_package_id != 3) || (this.loginInfo.is_sc_vip == 1 && this.loginInfo.sc_vip_level == 2 && item.vip_package_id == 1)
    },
    getList (page) {
      this.page = page;
      GlobalApi.getOrderList({
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.status == 1) {
          this.total = res.data.total;
          this.tableData = res.data.data;
          let data = this.tableData.filter((item) => item.payment_status == 1);
          this.setCountDown(data);
        }
      });
    },
    setCountDown (data) {
      data.forEach((item) => {
        let obj = {};
        obj.timer = setInterval(() => {
          let date = new Date().getTime() - new Date(item.created_at).getTime();
          date = 60 * 10 * 1000 - date > 0 ? 60 * 10 * 1000 - date : 0;
          if (date <= 0) {
            if (this.intervalObj[item.id]) {
              clearInterval(this.intervalObj[item.id].timer);
              delete this.intervalObj[item.id];
            }
            clearInterval(obj.timer);
            obj.minute = "00";
            obj.second = "00";
            obj.millisecond = "00";
            this.getList(1);
            return;
          }
          let minute = Math.floor(date / 1000 / 60);
          obj.minute = minute < 10 ? "0" + minute : minute;
          let second = Math.floor((date / 1000) % 60);
          obj.second = second < 10 ? "0" + second : second;
          let millisecond = Math.round((((date / 1000) % 60) % 1) * 100);
          millisecond = millisecond >= 100 ? 99 : millisecond;
          obj.millisecond = millisecond < 10 ? "0" + millisecond : millisecond;
          this.$set(this.intervalObj, item.id, obj);
        }, 50);
      });
    },
    //立即支付
    handlePay (item) {
      item.id = item.vip_package_id;
      item.price = item.payment_amount;
      this.payInfo.data = item;

      this.payInfo.out_no = item.order_id;
      this.payInfo.visible = true;
      this.payInfo.payment_channel = '订单中心';
    },
    //关闭支付弹窗
    closePay (refresh) {
      if (refresh) {
        this.getList(this.page);
        this.$store.dispatch("loginInfo")
      }
      this.payInfo.visible = false;
    },
    handleDelete (item) {
      this.$confirm("您的订单尚未完成支付，是否确认取消", "取消订单", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "banquan-message banquan-message-2",
        center: true,
      })
        .then(() => {
          GlobalApi.revokeOrder({ order_id: item.order_id }).then((res) => {
            if (res.status == 1) {
              this.getList(this.page);
              this.$message({
                type: "success",
                message: "订单已取消",
              });
            }
          });
        })
        .catch(() => { });
    },
  },
  beforeDestroy () {
    let data = this.tableData.filter((item) => item.payment_status == 1);
    data.forEach(item => {
      if (this.intervalObj[item.id]) {
        clearInterval(this.intervalObj[item.id].timer);
        delete this.intervalObj[item.id];
      }
    })
  }
};
</script>
<style scoped lang="scss">
::v-deep .el-table__empty-block {
  display: none;
}

.collect {
  padding: 28px 66px 40px 41px;

  .title {
    @include flex(center, space-between);
    height: 42px;
    margin-bottom: 29px;

    p {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      line-height: 28px;
    }
  }

  .el-table {
    ::v-deep .el-table__header-wrapper .el-table__cell {

      // padding-left: 25px;
      div {
        padding: 0 20px;
      }

      height: 84px;
      background-color: #f6f7f9;
      font-size: 14px;
      font-family: PingFangSC-Regular,
      PingFang SC;
      font-weight: 400;
      color: #333333;
      border: none;
    }

    ::v-deep .el-table__cell {
      height: 66px;

      div {
        padding: 0 20px;
      }

      // padding-left: 25px;
      font-size: 12px;
      font-family: PingFangSC-Regular,
      PingFang SC;
      font-weight: 400;
      color: #666666;
      border-color: #f2f2f2;

      .el-button {
        margin-bottom: 8px;

        &.none {
          background: #dbdbdb;
          color: #838383;
          cursor: not-allowed;
        }
      }

      .el-button--default {
        height: 34px;
        padding: 9px 29px;
        background: #f5f8fd;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7e87af;
        border: none;
      }

      .el-button--danger {
        border: none;
        padding: 9px 21px;
        height: 34px;
        background: #fff1f1;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff3131;
      }

      .el-button--success {
        border: none;
        padding: 9px 41px;
        height: 34px;
        background: #52c41a;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;

        &.not {
          opacity: 0.5;
          cursor: no-drop;
        }
      }

      .time {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 20px;
        margin-bottom: 2px;
        padding-left: 25px;

        span {
          display: inline-block;
          width: 20px;
          height: 20px;
          background: #ffffff;
          box-shadow: 0px 0px 4px 0px rgba(196, 196, 196, 0.5);
          border-radius: 2px;
          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }

  .el-pagination {
    text-align: center;
    margin: 20px auto;
  }
}
</style>
